import { prepareDate } from '@/utils/prepareTextAto.js';

export function filterByTipo(id, ato) {
    return ato ? id == ato.idTipoAto : false;
}

export function filterByAno(year, ato) {
    return ato ? year == ato.dataPublicacao.slice(0, 4) : false;
}

export function filterByOrgao(id, ato) {
    if (!ato.atoContent.orgaos) {
        return false;
    }
    return ato ? id == ato.atoContent.orgaos.idOrgao : false;
}

export function filterByIniciativa(id, ato) {
    return ato ? id == ato.idIniciativa : false;
}

export function filterByTema(id, ato) {
    if (!ato.atoContent.temas) {
        return false;
    }
    return ato ? id == ato.atoContent.temas.idTema : false;
}

export function filterByAll(obj, ato) {
    let filtered = true;
    switch (obj.key) {
        case "IdTipo":
            filtered &&= filterByTipo(obj.selected, ato);
            break;
        case "Ano":
            filtered &&= filterByAno(obj.selected, ato);
            break;
        case "IdOrgao":
            filtered &&= filterByOrgao(obj.selected, ato);
            break;
        case "IdIniciativa":
            filtered &&= filterByIniciativa(obj.selected, ato);
            break;
        case "IdTema":
            filtered &&= filterByTema(obj.selected, ato);
            break;
        default:
            break;

    }
    return filtered;
}

export function filterQuery(query, ato) {
    query = query.toLowerCase();
    let filtered = false;
  
    if (ato) {
        if (ato.atoContent) {
            // Conteudo
            if (ato.atoContent.conteudo && ato.atoContent.conteudo.conteudoHtml)
                filtered ||= ato.atoContent.conteudo.conteudoHtml.toLowerCase().includes(query);

            // Tema
            if (ato.atoContent.temas)
              if (ato.atoContent.temas.tema)
                if(ato.atoContent.temas.tema.descricao)
                  filtered ||= ato.atoContent.temas.tema.descricao.toLowerCase().includes(query);

            // Orgao
            if (ato.atoContent.orgaos)
              if (ato.atoContent.orgaos.orgao)
                if (ato.atoContent.orgaos.orgao.nome)
                  filtered ||= ato.atoContent.orgaos.orgao.nome.toLowerCase().includes(query);
        }

        // Data
        if (ato.dataPublicacao)
            filtered ||= prepareDate(ato.dataPublicacao).includes(query);

        // Iniciativa
        if (ato.iniciativa)
          if (ato.iniciativa.descricao)
            filtered ||= ato.iniciativa.descricao.toLowerCase().includes(query);

        // Palavras-Chave
        if (ato.palavrasChave)
            filtered ||= ato.palavrasChave.toLowerCase().includes(query);

        // Ementa
        if (ato.ementa)
            filtered ||= ato.ementa.toLowerCase().includes(query);

        // Tipo
        if (ato.tipoAto)
          if (ato.tipoAto.descricao)
            filtered ||= ato.tipoAto.descricao.toLowerCase().includes(query);

        // Numero
        if (ato.numero)
            filtered ||= ato.numero.toLowerCase().includes(query);
    }

    return filtered
}

<template>
    <div>
        <NavComponent></NavComponent>
        <v-container class="mb-4">
            <div class="d-flex flex-column">

                <div class="logoSearch d-flex align-items-center justify-content-center flex-wrap">
                    <router-link to="/" class="pt-2">
                        <LogoImage class="mb-4" pWidthLogo="16vw" pMaxWidthLogo="220" pWidthSubt="16vw" pMaxWidthSubt="220"
                            :subtEnabled="false" />
                    </router-link>
                    <v-flex grow class="inputAdvancedSearch">
                        <SearchBar :termo="value" @refresh="handleRefresh" @submitsearch="submitSearch"></SearchBar>
                    </v-flex>

                </div>
                <div id="main">
                    <Results :pRefresh="refresh" :termValue="value" @reset-refresh="handleResetRefresh"
                        @research="handleResearch"></Results>
                </div>

            </div>
        </v-container>
        <Footer class="mt-4" />
    </div>
</template>

<script>
import NavComponent from '@/shared/components/NavComponent.vue';
import SearchBar from '@/shared/components/SearchBar.vue';
import LogoImage from '@/shared/components/LogoImage.vue';
import Results from './components/Results.vue';
import Footer from '@/shared/components/Footer.vue';

export default {
    name: 'Search',
    title: "Pesquisa Avançada - LegisOn",
    data: () => {
        return ({
            refresh: false,
            value: ""
        })
    },
    // Se sair da página salva a página para na proxima página saber quaol era a pagina anterior.
    beforeRouteLeave(to, from, next) {
        localStorage.setItem('prevPage', from.path);
        next();
    },
    created() {
        window.addEventListener('beforeunload', this.handlePageRefresh);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    },
    mounted() {
        // Checa se houve alguma atualização na página para colocar que essa foi a página anterior caso tenha atualizado.
        if (this.isPageRefreshed() === true){
            localStorage.setItem('prevPage', this.$route.path);
        }
    },
    components: {
        NavComponent,
        SearchBar,
        Results,
        LogoImage,
        Footer
    },
    methods: {
        handleResearch: function (value) {
            this.value = value;
        },
        submitSearch: function (value) {
            this.value = value;
        },
        handleRefresh: function () {
            this.refresh = true;
        },
        handleResetRefresh: function () {
            this.refresh = false;
        },
        handlePageRefresh() {
            localStorage.setItem('pageRefresh', 'true');
        },
        isPageRefreshed() {
            const refreshed = localStorage.getItem('pageRefresh') === 'true';
            localStorage.removeItem('pageRefresh');
            return refreshed;
        }
    },
}
</script>

<style>
@import './Search.css';
</style>

<template>
  <div class="d-flex justify-content-center" id="dFlexResult">
    <Loading :pLoading="loading" />
    <SearchFilters @filter-change="handleFiltersChange"
      @number-change="handleNumberChange"
      @research="handleResearch"
      @sort-change="handleSortChange"
      @search-change="handleSearchChange"
      @att-change="handleAttChange"
      @tipopesquisa-change="handleTipoPesquisaChange"
      :resetFiltersTerms="resetFiltersTerms"
      @reseted-filters-terms="resetFiltersTerms = false"
      :num="number"
      :seme="sEmenta"
      :stxt="sTexto"
      :srt="loadSort"
      :term="terms"
      :tipoSearch="typeSearch"
      :anoSearch="yearSearch"
      :satt ="sAtualizado"
      :tpesquisa ="tipoPesquisa"
      v-if="!isSpecificSearch"></SearchFilters>

    <div id="Results" class="flex-grow-1">
      <div v-if="!filteredResults.length && !loading && !fetching"
           id="failedResult"
           class="d-flex justify-content-center align-items-center h-100">
        <div v-if="hasSearched" class="d-flex flex-column">
          <h2 id="h2Results">Não conseguimos encontrar nada com esses parâmetros de busca.</h2>
          <h4 id="h4Results">Experimente remover os filtros ou trocar as palavras-chave</h4>
        </div>
        <div v-else class="d-flex justify-content-center">
          <h2 id="h2Results" v-if="!isSpecificSearch">Para realizar uma busca, digite algo na barra de pesquisa acima ou use os parâmetros de busca ampla.</h2>
        </div>
      </div>

      <div v-else>
        <div class="d-flex">
          <h2 id="h2Results" class="text-left col-md-6 my-auto">{{resultMessageFounded}}</h2>
          <div v-if="resultMessage !== 'Resultados da pesquisa'" class="text-right my-auto w-100">
            <v-btn class="my-auto" title="Mais Recente." :id="'desc'" icon :color="loadSort === 'desc' ? '#339933' : '#444'" v-on:click="toggleSort(1)">
              <v-icon>{{ icons.mdiSortDesc }}</v-icon>
            </v-btn>
            <v-btn class="my-auto" title="Mais Antigo." :id="'asc'" icon :color="loadSort === 'asc' ? '#339933' : '#444'" v-on:click="toggleSort(2)">
              <v-icon>{{ icons.mdiSortAsc }}</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="constituicao">
          <CardConstituicao :ato="constituicao"></CardConstituicao>
        </div>
        <div v-for="result in prepareFilteredResults" :key="result.title" class="py-2">
          <CardDecreto :ato="result" />
        </div>
      </div>
      <v-btn v-if="displaySeeMore"
        plain
        :loading="fetching"
        elevation="0"
        color="#888"
        width="300"
        @click="handleLoadMore"
        class="mt-3">
        Ver mais
      </v-btn>
    </div>

    <div id="SidebarContainer">
      <Sidebar :results="results" :resultMessage="resultMessage" :filteredResults="filteredResults" :bodyFilter="bodyFilter"></Sidebar>
    </div>
  </div>

</template>

<script>
  //import axios from 'axios';
  import { filterQuery, filterByAll } from '@/utils/filters.js';
  import Sidebar from './Sidebar.vue';
  import CardConstituicao from './CardConstituicao';
  import SearchFilters from './SearchFilters.vue';
  import CardDecreto from './CardDecreto.vue';
  import Loading from '_shared/components/Loading.vue';
  import { mdiSortCalendarDescending } from '@mdi/js';
  import { mdiSortCalendarAscending } from '@mdi/js';
  import { mdiFormatLetterMatches } from '@mdi/js';
  import { mdiFileDelimited } from '@mdi/js';
  import { mdiFileDownload } from '@mdi/js';

  const defaultSortFunc = (a, b) => (a.priority < b.priority) ? 1 : -1;

  export default {
    name: 'Results',
    components: {
      Sidebar,
      SearchFilters,
      CardConstituicao,
      CardDecreto,
      Loading,
    },
    data: () => {
      return ({
        bodyFilter: {},
        loadSort: 'desc',
        icons: {
          mdiDonwloadCsv: mdiFileDelimited,
          mdiDonwloadXls: mdiFileDownload,
          mdiSortAsc: mdiSortCalendarAscending,
          mdiSortDesc: mdiSortCalendarDescending,
          mdiSortSearch: mdiFormatLetterMatches
        },
        constituicao: null,
        typeSearch: "",
        yearSearch: "",
        sAtualizado: false,
        sEmenta: true,
        sTexto: true,
        sortfunc: defaultSortFunc,
        seeMoreEnabled: false,
        loading: true,
        fetching: true,
        isSpecificSearch: false,
        isEmptySearch: false,
        ignoreQuery: false,
        ignoreFilters: false,
        resetFiltersTerms: false,
        hasSearched: false,
        page: 1,
        desiredAmount: 10,
        takeAmount: 10,
        minPriority: 0,
        filters: [],
        terms: "",
        results: [],
        filteredResults: [],
        newAtos: [],
        resultMessage: "Resultados da pesquisa",
        number: "",
        count: 0,
        dates: [],
        tipoPesquisa: 1
      })
    },
    props: {
      pRefresh: Boolean,
      termValue: String
    },
    methods: {
      exportFileAs: function(fileType) {
        // console.log(this.results);
        let searchResults = this.results;

        this.$http.get("/Public/ExportarPesquisa", { fileType, searchResults } )
        // .then(response => {
        //   const FILE = window.URL.createObjectURL(new Blob([response.data])) 
        // })
      },
      toggleSort: function (s) {
        if (s === 1) this.loadSort = 'desc';
        if (s === 2) this.loadSort = 'asc';
        if (s === 3) this.loadSort = 'match';
        this.handleSpecificSearch(this.$route, this.$route.params.q, false);
      },
      updateMinPriority: function () {
        this.minPriority =
          this.filters.length +
          (this.terms || this.$route.params.q? 1 : 0);

        // if (this.filters.find(f => f.key === "Ano")) this.minPriority -= 1;
      },
      handleLoadMore: function () {
        this.desiredAmount += this.takeAmount;
        if (this.$route.name === "Atos Atualizados") this.getConsolidados();
        else this.getAtos();
      },
      handleFiltersChange: function (filter) {
        this.ignoreFilters = false;
        this.isEmptySearch = false;
        const i = this.filters.findIndex(item => item.key == filter.key);
        if (filter.selected) {
          this.filters.push(filter);
          if (i > -1) this.filters.splice(i, 1);          
        } 
        else if (i > -1) this.filters.splice(i, 1);
        this.updateMinPriority();
        // this.filterResults();
      },
      handleTermsChange: function (terms) {
        this.ignoreFilters = false;
        this.isEmptySearch = false;
        this.terms = terms;
        this.updateMinPriority();
        this.filterResults();
      },
      handleNumberChange: function (number) {
        this.ignoreFilters = false;
        this.isEmptySearch = false;
        this.number = number;
        this.updateMinPriority();
        this.filterResults();
      },
      handleResearch: async function (recv, cons = false) {
        this.$emit("research", this.terms);
        if (this.minPriority <= 0) this.isEmptySearch = true;
        this.loading = true;
        this.hasSearched = true;
        this.page = 1;
        this.desiredAmount = this.takeAmount;
        this.seeMoreEnabled = true;
        this.ignoreQuery = true;
        this.results = []; 
        this.filteredResults = [];
        this.tipoPesquisa = recv;
        if (!cons) await this.getAtos();
        else await this.getConsolidados();
      },
      getConstituicao: function () {
        this.$http.get("/Public/GetConstituicao")
          .then(response => {
            this.constituicao = response.data;
          })
          .catch(error => {
            this.errored = true;
            this.errorMessage = error;
          })
      },

      loadCookies: function () {
        var postBodyCookieData = this.$cookies.get("postBodyCookie");
        var filtersCookieData = this.$cookies.get("filtersCookie");
        
        if(postBodyCookieData !== undefined && filtersCookieData !== undefined) {
          this.number = postBodyCookieData.Numero;
          this.sEmenta = postBodyCookieData.searchEmenta;
          this.sTexto = postBodyCookieData.searchTexto;
          this.tipoPesquisa = postBodyCookieData.TipoPesquisa;
          this.sAtualizado = postBodyCookieData.consolidado;
          this.loadSort = postBodyCookieData.Order;
          let filterTipo = filtersCookieData.find(x => x.key === "IdTipo");
          let filterAno = filtersCookieData.find(x => x.key === "ano");
          if (filterTipo !== undefined) {
            this.typeSearch = filterTipo.selected.join("-"); // [2, 6, 8] = "2-6-8"
          }
          if (filterAno !== undefined) {
          this.yearSearch = filterAno.selected.join("-"); // [2022, 2021, 2009] = "2022-2021-2009"
          }
        }
      },
      handleSpecificSearch: async function (rota, condition, reset = true, from = null) {
        this.updateMinPriority();
        this.isSpecificSearch = false;
        this.seeMoreEnabled = true;
        this.ignoreQuery = false;
        this.ignoreFilters = false;
        this.page = 1;
        this.desiredAmount = this.takeAmount;
        this.loading = true;
        this.hasSearched = true;
        this.results = [];
        this.filteredResults = [];
        this.constituicao = null;
        let idTipo = null;

        switch (rota.name) {
          case 'Busca':
            if (rota.params.q.match(/^(\d+\.*\d+)+$/g)) {
              this.number = rota.params.q;
              this.terms = "";
              this.loadSort = "match";
              rota.params.q = "";
              this.sEmenta = true;
              this.sTexto = true;
            }
            if (from) {
              var arr = ['Leis Complementares', 'Leis Ordinárias', 'Decretos Numerados', 'Emendas Constitucionais', 'Atos Atualizados'];
              if (arr.includes(from.name)) {
                var arr2 = ['lei complementar', 'lei ordinária', 'decreto numerado', 'constituição estadual e emendas', 'ato atualizado'];
                var index = arr.findIndex(x => x === from.name);
                if (index > -1) {
                  if (arr2[index] === 'ato atualizado') this.sAtualizado = true;
                  else this.typeSearch = arr2[index];
                }
              }
            }
            this.isEmptySearch = false
            this.ignoreFilters = true;
            this.resultMessage = "Resultados da pesquisa";
            if (condition) this.getAtos(rota.params.q);
            else this.loading = false;
            break;

          case 'Leis Complementares':
          case 'Leis Ordinárias':
          case 'Decretos Numerados':
          case 'Emendas Constitucionais':
            if (reset) {
              this.loadSort = "desc";
              this.sEmenta = false;
              this.sTexto = false;
              this.sAtualizado = false;
              this.resetFilters();
            }
            this.terms = "";
            this.resultMessage = rota.name;
            this.isSpecificSearch = true;
            this.filters = [];
            idTipo = Object.keys(this.$enumTipoAto).find(key => this.$enumTipoAto[key] === rota.path.slice(1).split('/').join(''))
            this.handleFiltersChange({ key: 'IdTipo', selected: [idTipo.toString()] });
            this.handleResearch();
            this.filterResults();
            if (rota.name === 'Emendas Constitucionais') this.getConstituicao();
            break;
          case 'Atos Atualizados':
            if (reset) {
              this.loadSort = "desc";
              this.sEmenta = false;
              this.sAtualizado = false;
              this.sTexto = false;
              this.resetFilters();
            }
            this.getConstituicao();
            this.terms = "";
            this.resultMessage = rota.name;
            this.isSpecificSearch = true;
            this.filters = [];
            idTipo = Object.keys(this.$enumTipoAto).find(key => this.$enumTipoAto[key] === rota.path.slice(1).split('/').join(''));
            await this.handleResearch(null, true);
            this.filterResults();
            break;
          case 'Ementário':
            this.resultMessage = rota.name;
            this.isSpecificSearch = true;
            this.isEmptySearch = true
            this.ignoreQuery = true;
            this.filters = [];
            this.getAtos();
            break;
          case 'BuscaVazia':
            if (!rota.params.q) {
              this.loadSort = "desc";
              this.sEmenta = false;
              this.sAtualizado = false;
              this.sTexto = false;
              this.resetFilters();
            }
            if (from) {
              var arr1 = ['Leis Complementares', 'Leis Ordinárias', 'Decretos Numerados', 'Emendas Constitucionais', 'Atos Atualizados'];
              if (arr1.includes(from.name)) {
                var arr3 = ['lei complementar', 'lei ordinária', 'decreto numerado', 'constituição estadual e emendas', 'ato atualizado'];
                var index1 = arr1.findIndex(x => x === from.name);
                if (index1 > -1) {
                  if (arr3[index1] === 'ato atualizado') this.sAtualizado = true;
                  else this.typeSearch = arr3[index1];
                }
              }
            }
            this.filters = [];
            this.results = []; this.filteredResults = [];
            this.hasSearched = false;
            this.loading = false;
            this.sortfunc = defaultSortFunc;
            this.fetching = false;
            this.resultMessage = "Resultados da pesquisa";
            break;
          default:
            break;
        }
        this.results = []; this.filteredResults = [];
      },
      resetFilters: function () {
        this.sEmenta = false;
        this.sAtualizado = false;
        this.sTexto = false;
        this.number = "";
        this.ano = 0;
        this.terms = "";
        this.loadSort = "desc";
      },
      handleSortChange: function (srt) {
        this.loadSort = srt;
      },
      handleSearchChange: function (obj) {
        this.sEmenta = obj.ementa;
        this.sTexto = obj.texto;
        this.tipoPesquisa = obj.tipoPesquisa
      },
      handleAttChange: function (att) {
        this.sAtualizado = att;
      },
      handleTipoPesquisaChange: function(tip) {
        this.tipoPesquisa = tip;
      },
      getConsolidados: async function () {
        this.fetching = true;
        this.newAtos = [];
        await this.$http.get("/Public/GetConsolidado?page=" + this.page + "&order=" + this.loadSort)
          .then(response => {
            this.newAtos = response.data.result.filter(ato => ato.disponivel);
            this.count = response.data.count;
            this.results = this.results.concat(this.newAtos);
            if (this.newAtos.length === 0) this.seeMoreEnabled = false;
            if (this.filteredResults.length > 0) this.loading = false;
          })
          .catch(error => {
            this.errored = true;
            this.errorMessage = error;
          })
          .finally(async () => {
            this.page++;
            await this.filterNewAtos();
          });
        this.fetching = false;
        this.loading = false;
      },
      handleRegex: async function (obj) {
        // DECRETO
        if (obj.PalavrasChave.match(/^(d|D)ecreto( (n|N)umerado)*( (n|N)(º|°|'|umero|úmero)*)* \d+\.*\d*$/)) {
          let num = obj.PalavrasChave.replace( /^\D+/g, '');
          obj.Numero = num;
          obj.Order = "match";
          obj.IdTipo = "4";
          obj.PalavrasChave = "";
          obj.searchEmenta = true;
          obj.searchTexto = true;

          this.sTexto = true;
          this.sEmenta = true;
          this.loadSort = "match";
          this.number = num.replace('.', '');
          this.typeSearch = "decreto numerado";
        }else
        // LEI COMPLEMENTAR
        if (obj.PalavrasChave.match(/^(l|L)ei (c|C)omplementar( (n|N)(º|°|'|umero|úmero)*)* \d+\.*\d*$/)) {
          let num = obj.PalavrasChave.replace( /^\D+/g, '');
          obj.Numero = num;
          obj.Order = "match";
          obj.IdTipo = "2";
          obj.PalavrasChave = "";
          obj.searchEmenta = true;
          obj.searchTexto = true;
          
          this.sTexto = true;
          this.sEmenta = true;
          this.loadSort = "match";
          this.number = num.replace('.', '');
          this.typeSearch = "lei complementar";
        }else
        // LEI ORDINÁRIA
        if (obj.PalavrasChave.match(/^(l|L)ei( (o|O)rdin(a|á)ria)*( (n|N)(º|°|'|umero|úmero)*)* \d+\.*\d*$/)) {
          let num = obj.PalavrasChave.replace( /^\D+/g, '');
          obj.Numero = num;
          obj.Order = "match";
          obj.IdTipo = "3";
          obj.PalavrasChave = "";
          obj.searchEmenta = true;
          obj.searchTexto = true;
          
          this.sTexto = true;
          this.sEmenta = true;
          this.loadSort = "match";
          this.number = num.replace('.', '');
          this.typeSearch = "lei ordinária";
        }else
        // EMENDAS 
        if (obj.PalavrasChave.match(/^(e|E)menda( (c|C)onstitucional)*( (n|N)(º|°|'|umero|úmero)*)* \d+\.*\d*$/)) {
          let num = obj.PalavrasChave.replace( /^\D+/g, '');
          obj.Numero = num;
          obj.Order = "match";
          obj.IdTipo = "8";
          obj.PalavrasChave = "";
          obj.searchEmenta = true;
          obj.searchTexto = true;
          
          this.sTexto = true;
          this.sEmenta = true;
          this.loadSort = "match";
          this.number = num.replace('.', '');
          this.typeSearch = "constituição estadual e emendas";
        }
        return obj;
      },
      getAtos: async function (term = null) {
        this.fetching = true;
        this.constituicao = null;
        if (term) {
          this.terms = term;
          this.sEmenta = true;
          this.sTexto = true;
        }
        var postBodyFilter = this.getPostBodyFilter;
        if (this.number) postBodyFilter.Numero = this.number;
        if (((postBodyFilter.consolidado && !postBodyFilter.IdTipo) || postBodyFilter.IdTipo.toString().includes("8"))
          && (!postBodyFilter.PalavrasChave && !postBodyFilter.Numero)
        ) this.getConstituicao();
        
        postBodyFilter = await this.handleRegex(postBodyFilter);
        this.bodyFilter = postBodyFilter;
        
        await this.$http.post("/Public/Consulta", postBodyFilter)
          .then(response => {
            this.newAtos = response.data.result.map(x => {
              if (x.ato) return x.ato;
              else return x;
            }).filter(ato => ato.disponivel);
            this.count = response.data.count;
            this.results = this.results.concat(this.newAtos);
            if (this.newAtos.length === 0) this.seeMoreEnabled = false;
            if (this.filteredResults.length > 0) this.loading = false;
          })
          .catch(error => {
            this.errored = true;
            this.errorMessage = error;
          }).finally(async () => {
              this.page++;
              await this.filterNewAtos();
          });
        this.fetching = false;
        this.loading = false;
        // Cookies para salvar dados da pesquisa avançada
        this.$cookies.remove("postBodyCookie");
        this.$cookies.remove("filtersCookie");
        this.$cookies.set("postBodyCookie", postBodyFilter, "9h");
        this.$cookies.set('filtersCookie', this.filters, "9h");
      },
      mapResults: function (ato) {
        let score = 0;
        if (!this.ignoreFilters) {
          if (this.filters.length > 0) {
            this.filters.map(filtro => {
              score += filterByAll(filtro, ato) ? 1 : 0
            });
          }
        }
        if (!this.ignoreQuery && this.$route.params.q) {
          let query = this.$route.params.q.match(/\w+|"[^"]+"/g).map(word => word.replace(/["]+/g, '')).filter((q) => q);
          score += query.filter(q => filterQuery(q, ato)).length;
        }

        ato.priority = score;
        return this.isEmptySearch || score >= this.minPriority;
      },
      filterNewAtos: async function () {
        this.filteredResults = await this.filteredResults.concat(this.newAtos);//.sort(this.sortfunc));
      },
      filterResults: async function () {
        this.filteredResults = await this.results.filter(ato => {
          var x = this.mapResults(ato);
          return x;
        });
        // if (sort) this.filteredResults.sort(this.sortfunc);
      }
   },
    computed: {
      resultMessageFounded: function () {
        if (this.resultMessage === 'Resultados da pesquisa')
          if (this.count === 1) return `${this.count.toLocaleString('en-us', { minimumIntegerDigits: 2 }).split(',').join('.')} resultado encontrado.`;
          else return `${this.count.toLocaleString('en-us', { minimumIntegerDigits: 2 }).split(',').join('.')} resultados encontrados.`;
        else return this.resultMessage;
      },
      getPostBodyFilter: function () {
        let postBody = {
          "Ementa": "",
          "IdIniciativa": 0,
          "IdOrgao": 0,
          "IdTema": 0,
          "IdTipo": "",
          "IdUsuarioCriador": 0,
          "Numero": "",
          "PalavrasChave": "",
          "Page": this.page,
          "Order": this.loadSort,
          "ano": "",
          "searchEmenta": this.sEmenta,
          "searchTexto": this.sTexto,
          "termos": this.terms,
          "consolidado": this.sAtualizado,
          "TipoPesquisa": this.tipoPesquisa
        };
        this.filters.map(filter => {
          if (postBody[filter.key] !== undefined) {
            postBody[filter.key] = filter.selected.join("-");
          }
        })
        if (postBody.ano === 0) postBody.ano = "";
        if (postBody.idTipo === 0) postBody.idTipo = "";
        postBody.PalavrasChave = this.terms;
        return postBody
      },
      displaySeeMore: function () {
        return this.filteredResults.length > 0 && this.seeMoreEnabled && this.filteredResults.length >= 10;
      },
      prepareFilteredResults: function () {
        return this.filteredResults.slice(0, this.desiredAmount * this.page);
      }
    },
    watch: {
      $route(to, from) {
        var arr = ['Leis Complementares', 'Leis Ordinárias', 'Decretos Numerados', 'Emendas Constitucionais', 'Atos Atualizados'];
        if (arr.includes(from.name)) {
          var arr2 = ['lei complementar', 'lei ordinária', 'decreto numerado', 'constituição estadual e emendas', 'ato atualizado'];
          var index = arr.findIndex(x => x === from.name);
          if (index > -1) {
            if (arr2[index] === 'ato atualizado') this.sAtualizado = true;
            else {
              this.typeSearch = arr2[index];
            }
          }
        }
        
        if (this.sAtualizado) this.handleSpecificSearch(to, to.params.q && to.params.q != from.params.q, true, from);
        else this.handleSpecificSearch(to, to.params.q && to.params.q != from.params.q);
      },

      pRefresh(value) {
        if (value) {
          this.ignoreQuery = false;
          this.filters = []; this.terms = "";
          this.resetFiltersTerms = true;
          this.results = []; this.filteredResults = [];
          this.handleSpecificSearch(this.$route, this.$route.params.q);
          this.$emit('reset-refresh');
        }
      },

      termValue(value) {
        if (value) {
          if (value.match(/^(\d+\.*\d+)+$/g)) {
            this.number = value;
            this.loadSort = "match";
            this.sEmenta = true;
            this.sTexto = true;
            this.terms = "";
          }
          else {
            this.terms = value;
            this.sEmenta = true;
            this.sTexto = true;
          }
        }
      }
    },
    mounted: async function () {
      await this.$http.get("/TipoAto/Get")
        .then(response => {
          this.$enumTipoAto = response.data.reduce(function (result, item) {
            let key = item[Object.keys(item)[0]];
            let value = item[Object.keys(item)[1]].replace(/\s/g, "-")                // remove os espaços
              .toLowerCase()                      // converte em minusculo
              .normalize("NFD")                   // disassocia acentos de letras
              .replace(/[\u0300-\u036f]/g, "");   // remove os acentos

            result[key] = value;
            return result;
          }, {});
        })
        .catch(error => {
          throw error;
        });
      this.filteredResults = this.results;
      this.handleSpecificSearch(this.$route, this.$route.params.q);
      // Se a última página for o visualizar ou a busca avançada checa se o cookie existe e carrega os Cookies.
      if(localStorage.getItem("prevPage").startsWith("/visualizar") || localStorage.getItem("prevPage").startsWith("/busca")){
        if(this.$cookies.isKey("postBodyCookie") && this.$cookies.isKey("filtersCookie")) {
          this.loadCookies();
        }
      }
      else { // Caso não venha do visualizar ou da busca avançada deleta os cookies para evitar problemas.
        this.$cookies.remove("postBodyCookie");
        this.$cookies.remove("filtersCookie");
      }
    },
  }
</script>

<style>
  @import './Results.css';
</style>

<template>
  <div class="geral">
    <v-tooltip top color="#3D3B3B">
      <template v-slot:activator="{on, attrs}">
        <div class="alinhamento">
        <v-icon
          class="botao"
          v-bind="attrs"
          v-on="on"
          color="#3D3B3B"
          @click="copy()"
        >
          {{iconCopy}}
        </v-icon>
        </div>
      </template>
      <span>Copiar</span>
    </v-tooltip>
    <v-hover v-slot="{ hover }">
        <v-lazy v-model="isActive"
                :options="{ threshold: .5 }"
                transition="fade-transition">
                <v-card :elevation="hover ? 4 : 2"
                        :class="{ 'on-hover': hover }"
                        color="#DFDFDF"
                        rounded="lg"
                        :to="preparePath()" >
                    <div class="card-results">
                      <div class="row content-card w-100 align-items-center mt-4">
                        <div class="col-sm-11 col-12 d-flex flex-column flex-sm-row">
                          <v-img :src="require(`@/assets/imgs/logodecreto.png`)"
                                 alt="Logo do Governo do Estado"
                                 class="my-sm-auto ml-2"
                                 contain
                                 max-height="46" />

                          <div class="text-left w-100">
                            <div class="d-flex flex-sm-row flex-column">
                              <div class="my-auto col-md-4 justify-content-start">
                                <v-card-title v-text="prepareTitle(ato)" class="text-sm-left text-center" />
                                <v-card-subtitle v-text="prepareDate(ato)" class="text-sm-left text-center" />
                              </div>
                              <div class="my-auto col-md-8">
                                <span class="ementaDesc text-sm-right text-center">{{ato.ementa}}</span>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        
                        <div class="col-sm-1 d-none d-sm-inline px-4">
                          <v-expand-transition>
                            <v-icon large
                            color="#3D3B3B"
                            class="transition-fast-in-fast-out v-card--reveal"
                            v-if="hover">
                            {{iconArrowRight}}
                            </v-icon>
                          </v-expand-transition>
                        </div>
                      </div>                   
                    </div>
                </v-card>
        </v-lazy>
    </v-hover>
  </div>
</template>

<script>
    import { mdiArrowRight } from '@mdi/js';
    import { mdiContentCopy } from '@mdi/js';

    import {
        prepareTitle as utilPrepareTitle,
        prepareDate as utilPrepareDate,
        preparePath as utilPreparePath,
    } from '@/utils/prepareTextAto.js';

    export default {
        name: 'CardDecreto',
        data: () => {
            return ({
                iconArrowRight: mdiArrowRight,
                iconCopy: mdiContentCopy,
                isActive: false,
                pathObj: null,
            })
        },
        props: {
            ato: {
                type: Object
            },
        },
        methods: {
            copy() {
              var copiaTudo = this.ato.tipoAto.descricao + " Nº " + this.ato.numero + "\n\n" + this.ato.ementa.split("\n").join("");
              navigator.clipboard.writeText(copiaTudo);
            },
              prepareTitle: function () {
                return utilPrepareTitle(this.ato.tipoAto.descricao, this.ato.numero).toUpperCase();
            },
            prepareDate: function () {
                if (this.ato.dataAto.toString() !== "0001-01-01T00:00:00")
                    return `de ${utilPrepareDate(this.ato.dataAto)}`.toUpperCase();
                else return `de ${utilPrepareDate(this.ato.dataPublicacao)}`.toUpperCase();
            },
            preparePath: function () {
                return utilPreparePath("Visualização Ato", this.$enumTipoAto[this.ato.tipoAto.id], this.ato.numero, this.ato);
            },
        }
    }
</script>

<style>
    @import './CardDecreto.css';
</style>

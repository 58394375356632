<template>
  <div id="Filter" class="d-flex flex-column mb-lg-3 mt-lg-3">
    <DialogError :errorDialog="errored"
                 title="Parece que estamos com alguns problemas"
                 message="Não foi possível carregar esta página agora. Tente novamente mais tarde..." />
    <label id="titleParam" class="d-flex text-left">Parâmetros para busca ampla</label>
    <div class="d-flex flex-column">
      <div data-app class="elFilter d-flex flex-column"
           v-for="filter in filtersDropdown"
           :key="filter.title">
        <label class="text-left">{{filter.title}}</label>
        <v-autocomplete :items="filter.options"
                        :item-text="item => item.nome ? item.nome : item.descricao"
                        :item-value="'id'"
                        multiple
                        v-model="filter.selected"
                        clearable
                        solo
                        no-data-text="Não há nada por enquanto"
                        :menu-props="{transition: 'scroll-y-transition'}"
                        @input="handleChange(filter)">
          <template v-slot:append>
            <v-icon class="pointer">{{iconDown}}</v-icon>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <div class="d-flex flex-column">
      <div class="text-left">
        <label class="text-left">Número do Ato</label>
        <v-text-field solo @input="handleNumberChange()" v-model="number" type="number">
        </v-text-field>
      </div>
    </div>
    <div v-if="(number !== null && number !== '') || (term)" class="flex-column mt-4">
      <label class="text-center mt-5">Pesquisar Em</label>
      <div class="mb-4">
        <v-checkbox class="mb-0" v-model="sEmenta" label="Ementa" v-on:click="handleSearchChange()"></v-checkbox>
        <br />
        <v-checkbox class="mt-0" v-model="sTexto" label="Texto" v-on:click="handleSearchChange()"></v-checkbox>
      </div>
      <label class="text-center mt-5">Tipo de Pesquisa</label>
      <div class="mb-4">
        <v-radio-group v-model="radioGroup" v-on:click="handleTipoPesquisaChange()">
          <v-radio label=" Aproximada" :value="1">Aproximada</v-radio>
          <v-radio label=" Exata" :value="2">Exata</v-radio>
        </v-radio-group>
      </div>

    </div>
    <div class="justify-content-left d-flex flex-column mt-4">
      <div class="row justify-content-left d-flex mt-4">
        <v-checkbox class="my-auto" v-model="sAtualizado" v-on:click="handleAttChange()"></v-checkbox>
        <h4 class="ml-1 mt-3">Ato Atualizado</h4>
      </div>
    </div>
    <label class="text-center mt-5">Ordenar Por</label>
    <div class="justify-content-center d-flex flex-column mt-4 mb-5">
      <div class="row justify-content-center d-flex mb-5">
        <v-btn title="Mais Recente." :id="'desc'" text :color="sort === 'desc' ? '#339933' : '#444'" v-on:click="handleSortChange('desc')">
          <v-icon>{{icons.mdiSortDesc}}</v-icon>
        </v-btn>
        <v-btn title="Mais Antigo." :id="'asc'" text :color="sort === 'asc' ? '#339933' : '#444'" v-on:click="handleSortChange('asc')">
          <v-icon>{{icons.mdiSortAsc}}</v-icon>
        </v-btn>
        <div v-if="number !== null && number !== '' ? true : resetOrder()">
          <v-btn title="Relevância." :id="'match'" text :color="sort === 'match' ? '#339933' : '#444'" v-on:click="handleSortChange('match')">
            <v-icon>{{icons.mdiSortSearch}}</v-icon>
          </v-btn>
        </div>
        <v-btn v-else plain disabled><v-icon>{{icons.mdiSortSearch}}</v-icon></v-btn>
      </div>
    </div>
    <v-btn class="mt-5 text-light"
           color="#444444"
           block
           id="buttonSearchByFilters"
           @click="research">
      Buscar por filtros
    </v-btn>
  </div>
</template>

<script>
    import DialogError from '@/shared/components/DialogError.vue';

    import { mdiChevronDown } from '@mdi/js';
    import { mdiSortCalendarDescending } from '@mdi/js';
    import { mdiSortCalendarAscending } from '@mdi/js';
    import { mdiFormatLetterMatches } from '@mdi/js';

    const firstYear = 1900;

    export default {
        name: 'SearchFilters',
        components: {
            DialogError,
        },
        props: {
            resetFiltersTerms: Boolean,
            num: String,
            seme: Boolean,
            stxt: Boolean,
            srt: String,
            tipoSearch: String,
            anoSearch: String,
            satt: Boolean,
            term: String,
            tpesquisa: Number
        },
        data: () => {
            return ({
                radioGroup:1,
                options: [],
                number: "",
                sEmenta: false,
                sAtualizado: false,
                sTexto: false,
                sort: 'desc',
                iconDown: mdiChevronDown,
                errored: false,
                errorMessage: null,
                loading: true,
                filtersDropdown: [
                    {
                        title: "Tipo de ato",
                        key: "IdTipo",
                        api: "/TipoAto/Get",
                        selected: "",
                        options: []
                    },
                    {
                        title: "Ano",
                        key: "ano",
                        api: "",
                        selected: "",
                        options: []
                    },
                    /*{
                        title: "Órgão",
                        key: "IdOrgao",
                        api: "/Orgao/Get",
                        selected: "",
                        options: []
                    },
                    {
                        title: "Iniciativa",
                        key: "IdIniciativa",
                        api: "/Iniciativa/Get",
                        selected: "",
                        options: []
                    },
                    {
                        title: "Tema",
                        key: "IdTema",
                        api: "/Tema/Get",
                        selected: "",
                        options: []
                    },*/
                ],
                icons: {
                    mdiSortAsc: mdiSortCalendarAscending,
                    mdiSortDesc: mdiSortCalendarDescending,
                    mdiSortSearch: mdiFormatLetterMatches
                },

                inputTerms: []
            })
        },
        methods: {
            getItemText: function () {
                item => item.nome
            },
            resetOrder: function () {
              if (!this.num && this.sort == 'match')
                this.handleSortChange("desc");
              return false;
            },
            handleChange: function (filter) {
              this.$emit('filter-change', { key: filter.key, selected: filter.selected });
            },
            handleNumberChange: function () {
                this.$emit('number-change', this.number)
            },
            handleSortChange: function (srt) {
                this.sort = srt;
                this.$emit('sort-change', srt);
            },
            handleSearchChange: function () {
                this.$emit('search-change', { ementa: this.sEmenta, texto: this.sTexto, tipoPesquisa: this.radioGroup });
            },
            handleAttChange: function () {
              this.$emit('att-change', this.sAtualizado);
            },
            handleTipoPesquisaChange: function () {
              this.$emit('tipopesquisa-change', this.tipoPesquisa);
              this.radioGroup = this.tipoPesquisa;
            },
            yearsList: function () {
                var currentYear = new Date().getFullYear();
                var qtt = currentYear - firstYear + 1;
                var years = [...Array(qtt).keys()];

                return years.map(year => currentYear - year);
            },
            getFilters: async function () {
                await this.filtersDropdown.map(async filter => {
                    if (filter.api) {
                        await this.$http.get(filter.api)
                            .then(response => {
                              if (filter.title === 'Tipo de ato') {
                                filter.options = response.data.filter(x => !x.descricao.toLowerCase().startsWith('parecer') && !x.descricao.toLowerCase().startsWith('ofício') && !x.descricao.toLowerCase().startsWith('processo'));
                                filter.options.find(x => x.descricao.toLowerCase().startsWith('emenda')).descricao = "Constituição Estadual e Emendas";
                                this.options = filter.options;
                              }
                              else filter.options = response.data;
                            })
                            .catch(error => {
                                console.log(error);
                                this.errored = true;
                                this.errorMessage = error;
                            })
                            .finally(() => { this.loading = false })
                    }
                })
            },
            research: function () {
                this.$emit('research', this.radioGroup);
            }
        },
        watch: {
            resetFiltersTerms() {
                this.inputTerms = []
                this.filtersDropdown.map(item => item.selected = null)
                this.$emit('reseted-filters-terms')
            },
            filt(value) {
              this.filter = value;
            },
            num(value) {
              this.number = value;
            },
            seme(value) {
              this.sEmenta = value;
            },
            stxt(value) {
              this.sTexto = value;
            },
            tpesquisa(value) {
              this.radioGroup = value;
            },
            srt(value) {
              if (value)
                this.sort = value;
            }, // Ao invés de vir um string vir um array
            tipoSearch(value) { 
              if (value) {
                if (value.includes("-")) {
                  let selecteds = value.split("-").map(x => parseInt(x));
                  let filterType = this.filtersDropdown.find(x => x.key === "IdTipo");
                  if (filterType) {
                    filterType.selected = selecteds;
                    this.handleChange(filterType);
                  }
                }else {
                  let filterType = this.filtersDropdown.find(x => x.key === "IdTipo");
                  if (filterType) {
                      var tipo = filterType.options.find(x => x.id === parseInt(value));
                      if (tipo) {
                        filterType.selected = [tipo.id];
                        this.handleChange(filterType);
                      }
                  }
                }
              }
            },
            anoSearch(value) {
              if (value.includes("-")) {
                let selecteds = value.split("-").map(x => parseInt(x));
                let filterAno = this.filtersDropdown.find(x => x.key === "ano");
                if (filterAno) {
                  filterAno.selected = selecteds;
                  this.handleChange(filterAno);
                }
              } else {
                let filterAno = this.filtersDropdown.find(x => x.key === "ano");
                if (filterAno) {
                    let ano = filterAno.options.find(x => x === parseInt(value));
                    if(ano) {
                      filterAno.selected = [ano];
                      this.handleChange(filterAno);
                    }
                }
              }
            },
            satt(value) {
              this.sAtualizado = value;
            }
        },
        computed: {
          getOptions: function () {
            return this.options;
          }
        },
        async mounted() {
          if (this.num)
            this.number = this.num;
          if (this.seme)
            this.sEmenta = this.seme;
          if (this.stxt)
            this.sTexto = this.stxt;
          if (this.srt)
            this.sort = this.srt;
          if (this.satt)
            this.sAtualizado = this.satt;
          if (this.tpesquisa)
            this.radioGroup = this.tpesquisa;

          await this.getFilters();
          this.filtersDropdown
            .find(el => el.title === "Ano")
            .options = this.yearsList();

          setTimeout(() => {
            if (this.tipoSearch) {
              var filterType = this.filtersDropdown.find(el => el.title === "Tipo de ato");
              if (this.options) {
                var tipo = this.options.find(x => x.descricao.toLowerCase() === this.tipoSearch.toLowerCase());
                if (tipo) {
                  filterType.selected = [tipo.id];
                  this.handleChange(filterType);
                }
              }
            }
          }, 500);
        }
    }
</script>

<style>
    @import './SearchFilters.css';
</style>

<template>
    <div
      id="Sidebar"
      class="d-flex flex-row flex-md-column justify-content-between justify-content-lg-start mb-lg-3"
    >
      <div class="iconButton">
        <v-tooltip max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-3 mx-md-0"
              icon
              color="transparent"
              elevation="0"
              :disabled="estaDesativado"
              v-on:click="exportFileAs('csv')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon large color="#3D3B3B">{{ icons.mdiDonwloadCsv }}</v-icon>
            </v-btn>
          </template>
          <span class="ml-12"
            ><span class="font-weight-bold">Nota:</span> devido ao uso constante
            de ponto e vírgula nos textos, a separação de colunas utiliza | em vez
            do tradicional ;</span
          >
        </v-tooltip>
        <label class="h5">CSV</label>
      </div>
  
      <div class="iconButton">
        <v-btn
          class="mx-3 mx-md-0"
          icon
          color="transparent"
          elevation="0"
          :disabled="estaDesativado"
          v-on:click="exportFileAs('xlsx')"
        >
          <v-icon large color="#3D3B3B">{{ icons.mdiDonwloadXls }}</v-icon>
        </v-btn>
        <label class="h5">XLS</label>
      </div>
    </div>
  </template>
  
  <script>
  import { mdiFileDelimited } from "@mdi/js";
  import { mdiFileDownload } from "@mdi/js";
  
  export default {
    name: "Sidebar",
    data: () => {
      return {
        disabled: false,
        icons: {
          mdiDonwloadCsv: mdiFileDelimited,
          mdiDonwloadXls: mdiFileDownload,
        },
      };
    },
    props: {
      results: Array,
      resultMessage: String,
      filteredResults: Array,
      bodyFilter: Object,
    },
    computed: {
      estaDesativado: function () {
        // Desabilita dependend da página.
        // if (this.resultMessage == "disabled") return !this.disabled;
        if (this.results.length == 0) return !this.disabled;
        return this.disabled;
      },
    },
    methods: {
      exportFileAs: function (file_type) {
        // Corpo da consulta dos atos.
        let bodyFilter = this.bodyFilter;
  
        // Define o endpoint e o tipo do blob. CSV como padrão.
        let endpoint = "/Public/DownloadResultsAsCSV";
        let type = "text/csv;charset=utf-8";
  
        if (file_type === "xlsx") {
          endpoint = "/Public/DownloadResultsAsXLSX";
          type = "application/vnd.ms-excel";
        }
  
        this.$http
          .post(endpoint, bodyFilter, {
            responseType: "blob", // Importante.
          })
          .then((response) => {
            const FILE = new Blob([response.data], {
              type: type,
            });
  
            // Cria uma tag <a/> e posteriormente define seus atributos para o download.
            const url = URL.createObjectURL(FILE);
            const link = document.createElement("a");
  
            link.setAttribute("href", url);
            link.setAttribute("download", `LegisOnFiles.${file_type}`);
            link.click();
          })
          .catch((err) => console.log(err));
      },
    },
  };
  </script>
  
  <style>
  @import "./Sidebar.css";
  </style>